<template>
    <img src="../assets/img/user.png" alt="">
</template>

<style lang="scss" scoped>
    img {
        width: 34px;
        height: 34px;
        
        @media screen and (max-width: 640px) {
        width: 30px;
        height: 30px;
      }
    }
</style>

<script>
export default {
    name: "LoginIcon",
}
</script>
