<template>
  <!-- Code formatting could break stuff -->
  <i>
    <svg
      v-if="icon == 'loupe'"
      class="loupe"
      version="1.1"
      id="fi_263064"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M457.602,54.355c-72.417-72.416-190.245-72.416-262.661,0c-35.081,35.079-54.399,81.721-54.399,131.331
			c0,45.193,16.039,87.917,45.413,121.688l-22.119,22.119l-22.542-22.542c-2.47-2.47-5.821-3.858-9.314-3.858
			c-3.493,0-6.844,1.388-9.314,3.858L17.055,412.563C6.057,423.559,0,438.18,0,453.733c0,15.552,6.057,30.174,17.053,41.17
			c10.998,10.998,25.619,17.054,41.17,17.054c15.551,0,30.174-6.057,41.17-17.053l105.612-105.61c2.47-2.47,3.858-5.821,3.858-9.314
			c0-3.493-1.388-6.844-3.858-9.314l-22.542-22.542l22.126-22.126c34.793,30.215,78.234,45.331,121.682,45.331
			c47.561,0,95.123-18.104,131.331-54.311C492.68,281.938,512,235.298,512,185.688C512,136.075,492.682,89.434,457.602,54.355z
			M80.765,476.275c-6.021,6.021-14.026,9.337-22.542,9.337c-8.515,0-16.521-3.317-22.542-9.338
			c-6.02-6.02-9.337-14.026-9.337-22.54s3.317-16.521,9.338-22.542l58.934-58.934L139.7,417.34L80.765,476.275z M158.33,398.711
			l-45.084-45.084l18.734-18.734l45.084,45.085L158.33,398.711z M438.973,298.388c-62.144,62.146-163.259,62.146-225.403,0
			c-30.104-30.104-46.683-70.128-46.683-112.702c0-42.574,16.579-82.598,46.683-112.701c31.072-31.072,71.887-46.609,112.702-46.609
			c40.814,0,81.63,15.535,112.702,46.609c30.104,30.103,46.683,70.128,46.683,112.701S469.077,268.284,438.973,298.388z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M417.234,94.721c-50.158-50.156-131.769-50.158-181.927,0c-50.156,50.158-50.156,131.769,0.001,181.927
			c25.079,25.077,58.02,37.617,90.963,37.617s65.885-12.54,90.964-37.617v-0.001C467.391,226.491,467.391,144.879,417.234,94.721z
			M398.605,258.02c-39.886,39.886-104.783,39.886-144.669,0.001c-39.886-39.886-39.886-104.784-0.001-144.67
			c19.945-19.946,46.136-29.914,72.336-29.914c26.193,0,52.394,9.974,72.334,29.914C438.491,153.236,438.491,218.134,398.605,258.02
			z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M375.321,136.636c-27.048-27.045-71.053-27.045-98.1,0c-5.144,5.144-5.144,13.484,0,18.63
			c5.144,5.144,13.484,5.144,18.63,0c16.772-16.774,44.068-16.774,60.842,0c2.573,2.573,5.943,3.858,9.314,3.858
			c3.371,0,6.743-1.286,9.314-3.858C380.465,150.122,380.465,141.781,375.321,136.636z"
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
    <svg
      v-if="icon == 'basket'"
      version="1.1"
      id="fi_263063"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512.001 512.001"
      style="enable-background: new 0 0 512.001 512.001"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M512,256.001c0-10.821-6.012-20.551-15.694-25.398l-38.122-19.061l38.122-19.06c0.001-0.001,0.004-0.002,0.004-0.002
			c9.68-4.845,15.692-14.576,15.692-25.397c0-10.819-6.013-20.55-15.694-25.397L281.09,34.08
			c-15.712-7.849-34.47-7.849-50.185,0.001L15.691,141.691C6.013,146.534,0,156.264,0,167.084c0,10.821,6.012,20.551,15.694,25.398
			l38.121,19.06l-38.126,19.063C6.012,235.45,0,245.18,0,256.001s6.012,20.551,15.694,25.397l38.121,19.061l-38.118,19.059
			C6.02,324.353,0.004,334.08,0,344.902c-0.004,10.828,6.008,20.564,15.694,25.412l215.215,107.608
			c7.856,3.925,16.471,5.886,25.09,5.886c8.619,0,17.238-1.963,25.095-5.887l215.215-107.608
			c9.682-4.845,15.695-14.582,15.691-25.41c-0.004-10.822-6.02-20.549-15.694-25.381l-38.122-19.061l38.126-19.063
			C505.988,276.552,512,266.822,512,256.001z M26.225,171.431c-2.339-1.171-2.687-3.226-2.687-4.346s0.35-3.175,2.683-4.343
			L241.429,55.138c4.563-2.28,9.568-3.418,14.573-3.418c5.003,0,10.007,1.139,14.567,3.417L485.776,162.74
			c2.337,1.17,2.687,3.225,2.687,4.345s-0.348,3.175-2.687,4.346L270.572,279.032c-9.125,4.558-20.019,4.558-29.139,0.001
			L26.225,171.431z M485.783,340.575c2.33,1.164,2.679,3.215,2.679,4.336c0.001,1.123-0.348,3.182-2.683,4.35L270.571,456.865
			c-9.125,4.558-20.019,4.559-29.139,0.001L26.225,349.262c-2.339-1.171-2.688-3.229-2.687-4.352c0-1.119,0.348-3.171,2.683-4.337
			l53.912-26.956l150.776,75.387c7.856,3.925,16.471,5.886,25.089,5.886c8.619,0,17.238-1.963,25.095-5.887l150.772-75.386
			L485.783,340.575z M485.778,260.345L270.571,367.949c-9.125,4.558-20.019,4.559-29.139,0.001L26.225,260.347
			c-2.339-1.17-2.687-3.225-2.687-4.345c0-1.122,0.348-3.175,2.683-4.344l53.912-26.956l150.776,75.387
			c7.855,3.925,16.472,5.886,25.089,5.886c8.617,0,17.237-1.962,25.094-5.888l150.774-75.386l53.908,26.954
			c2.339,1.171,2.687,3.225,2.687,4.346C488.462,257.121,488.113,259.176,485.778,260.345z"
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
    <svg
      v-if="icon == 'download'"
      version="1.1"
      id="fi_263096"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M498.966,339.946c-7.197,0-13.034,5.837-13.034,13.034v49.804c0,28.747-23.388,52.135-52.135,52.135H78.203
			c-28.747,0-52.135-23.388-52.135-52.135V352.98c0-7.197-5.835-13.034-13.034-13.034C5.835,339.946,0,345.782,0,352.98v49.804
			c0,43.121,35.082,78.203,78.203,78.203h355.594c43.121,0,78.203-35.082,78.203-78.203V352.98
			C512,345.782,506.165,339.946,498.966,339.946z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M419.833,391.3H92.167c-7.197,0-13.034,5.837-13.034,13.034s5.835,13.034,13.034,13.034h327.665
			c7.199,0,13.034-5.835,13.034-13.034C432.866,397.137,427.031,391.3,419.833,391.3z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M387.919,207.93c-4.795-5.367-13.034-5.834-18.404-1.038l-100.482,89.765V44.048c0-7.197-5.835-13.034-13.034-13.034
			c-7.197,0-13.034,5.835-13.034,13.034v252.609l-100.482-89.764c-5.367-4.796-13.607-4.328-18.404,1.038
			c-4.794,5.369-4.331,13.609,1.037,18.404l109.174,97.527c6.187,5.529,13.946,8.292,21.708,8.292
			c7.759,0,15.519-2.763,21.708-8.289l109.174-97.53C392.25,221.537,392.714,213.297,387.919,207.93z"
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
    <svg
      v-if="icon == 'trash'"
      version="1.1"
      id="fi_263067"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M465.423,48.241h-137.61V23.955C327.813,10.746,317.082,0,303.893,0h-95.785c-13.19,0-23.92,10.746-23.92,23.955V48.24
			H46.577c-6.655,0-12.049,5.394-12.049,12.049c0,6.655,5.394,12.049,12.049,12.049h22.332l15.228,396.396
			C85.069,492.995,104.818,512,129.099,512h253.804c24.281,0,44.03-19.006,44.96-43.267l15.228-396.396h22.332
			c6.653,0,12.049-5.394,12.049-12.049C477.472,53.635,472.078,48.241,465.423,48.241z M208.285,24.097h95.43v24.143h-95.43V24.097z
			M403.784,467.809c-0.433,11.268-9.605,20.094-20.882,20.094H129.099c-11.276,0-20.448-8.827-20.882-20.095L93.025,72.338h325.952
			L403.784,467.809z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M182.63,181.571c-0.127-6.575-5.494-11.817-12.042-11.817c-0.078,0-0.158,0-0.236,0.002
			c-6.652,0.128-11.943,5.626-11.815,12.278l3.781,196.634c0.126,6.575,5.495,11.817,12.042,11.817c0.078,0,0.158,0,0.236-0.002
			c6.653-0.128,11.943-5.624,11.815-12.278L182.63,181.571z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.998,169.753c-6.654,0-12.049,5.394-12.049,12.049v196.634c0,6.654,5.394,12.049,12.049,12.049
			c6.655,0,12.049-5.394,12.049-12.049V181.802C268.047,175.148,262.653,169.753,255.998,169.753z"
          ></path>
        </g>
      </g>
      <g>
        <g>
          <path
            d="M341.645,169.756c-6.628-0.147-12.151,5.162-12.278,11.815l-3.781,196.634c-0.129,6.653,5.162,12.15,11.815,12.278
			c0.078,0.001,0.158,0.002,0.236,0.002c6.546,0,11.916-5.244,12.042-11.817l3.781-196.634
			C353.588,175.38,348.299,169.883,341.645,169.756z"
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
    <svg
      v-if="icon == 'adjustment'"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 612 612"
      style="enable-background: new 0 0 612 612"
      xml:space="preserve"
    >
      <g>
        <g id="_x36__13_">
          <g>
            <path
              d="M122.4,267.261V40.8c0-11.261-9.139-20.4-20.4-20.4S81.6,29.54,81.6,40.8v226.46C35.047,276.706,0,317.854,0,367.201
				s35.047,90.494,81.6,99.939v104.061c0,11.26,9.139,20.398,20.4,20.398s20.4-9.139,20.4-20.398V467.141
				c46.553-9.445,81.6-50.592,81.6-99.939S168.953,276.706,122.4,267.261z M102,428.4c-33.803,0-61.2-27.396-61.2-61.199
				S68.197,306,102,306s61.2,27.398,61.2,61.201S135.803,428.4,102,428.4z M326.4,63.261V40.8c0-11.261-9.14-20.4-20.4-20.4
				s-20.4,9.139-20.4,20.4v22.46c-46.553,9.445-81.6,50.592-81.6,99.94s35.047,90.494,81.6,99.939v308.061
				c0,11.26,9.139,20.398,20.4,20.398s20.4-9.139,20.4-20.398V263.14c46.553-9.445,81.6-50.592,81.6-99.939
				S372.953,72.706,326.4,63.261z M306,224.4c-33.803,0-61.2-27.397-61.2-61.2S272.197,102,306,102s61.2,27.397,61.2,61.2
				S339.803,224.4,306,224.4z M530.4,348.861V40.8c0-11.261-9.14-20.4-20.4-20.4s-20.4,9.139-20.4,20.4v308.061
				c-46.553,9.445-81.6,50.592-81.6,99.939s35.047,90.494,81.6,99.939v22.461c0,11.26,9.14,20.398,20.4,20.398
				s20.4-9.139,20.4-20.398V548.74c46.553-9.445,81.6-50.592,81.6-99.939S576.953,358.307,530.4,348.861z M510,510
				c-33.803,0-61.2-27.396-61.2-61.199S476.197,387.6,510,387.6s61.2,27.398,61.2,61.201S543.803,510,510,510z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </i>
</template>

<style lang="scss" scoped>
i {
  display: inline-block;
  
  svg {
    width: 2.5rem;
    
    @media screen and (max-width: 640px) {
      width: 2.1rem;
      }
  }

  .loupe {
    width: 2.3rem;
    @media screen and (max-width: 640px) {
      width: 2.0rem;
      }
  }
}
</style>

<script>
export default {
  name: "FlatIcon",
  props: ["icon"],
};
</script>
